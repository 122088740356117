import React, { FC } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { useHistory } from 'react-router-dom';

export type ClickProps = {
  inputChange: React.Dispatch<
    React.SetStateAction<{ input: string; selection: number }>
  >;
  convertReset: () => void;
  inputRef: React.RefObject<HTMLTextAreaElement>;
};

const KeyClickButton: FC<ClickProps> = (props) => {
  const { children, inputChange, convertReset, inputRef } = props;
  const history = useHistory();

  return (
    <Tooltip title="Delete All" arrow>
      <IconButton
        onClick={() => {
          convertReset();
          inputChange({ input: '', selection: 0 });
          history.push('/?formula=');
          inputRef.current?.focus();
        }}
        style={{ color: 'rgba(0, 0, 0, 0.33)' }}
      >
        {children}
      </IconButton>
    </Tooltip>
  );
};

export default KeyClickButton;
