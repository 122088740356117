import React, { FC } from 'react';
import AppsIcon from '@material-ui/icons/Apps';
import AddIcon from '@material-ui/icons/Add';
import FunctionsIcon from '@material-ui/icons/Functions';
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot';
import KeyFormButton, { KeyProps } from './KeyFormButton';
import { ClickProps } from './KeyClickButton';

const KeyFormButtons: FC<KeyProps & ClickProps> = (props) => {
  const { keyState, keyChange } = props;

  return (
    <>
      <KeyFormButton title="NUMBER" count={3} {...{ keyState, keyChange }}>
        <AppsIcon fontSize="small" />
      </KeyFormButton>
      <KeyFormButton title="OPERATOR" count={4} {...{ keyState, keyChange }}>
        <AddIcon fontSize="small" />
      </KeyFormButton>
      <KeyFormButton title="FUNCTIONS" count={5} {...{ keyState, keyChange }}>
        <FunctionsIcon fontSize="small" />
      </KeyFormButton>
      <KeyFormButton title="UNITS" count={6} {...{ keyState, keyChange }}>
        <ScatterPlotIcon fontSize="small" />
      </KeyFormButton>
    </>
  );
};

export default KeyFormButtons;
