const weightCorrection = (
  kind: string | undefined,
  value: number,
  unit: string,
): { value: number; unit: string } => {
  if (kind === 'Weight') {
    return {
      value: value * 1000,
      unit: unit.replace('(kg)', '(g)'),
    };
  }

  return { value, unit };
};

export default weightCorrection;
