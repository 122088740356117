import { useReducer } from 'react';
import * as types from './convertTypes';
import convertSlice from './convertReducers';

const useConvert = () => {
  const initialState: types.convertTypes = [];
  const [convertState, convertDispatch] = useReducer(
    convertSlice.reducer,
    initialState,
  );
  const {
    calc,
    reset,
    cloneCalc,
    cloneAdd,
    cloneDelete,
    convertOpenAction,
    prefixCalcAction,
    variableCalcAction,
  } = convertSlice.actions;

  const convertCalc: types.convertCalcFc = (inputValue) =>
    convertDispatch(calc(inputValue));
  const convertReset = (): void => convertDispatch(reset);

  const copyCalc: types.copyCalcFc = (result) =>
    convertDispatch(cloneCalc(result));
  const copyAdd: types.copyAddFc = (result) =>
    convertDispatch(cloneAdd(result));
  const copyDelete: types.copyDeleteFc = (ids) =>
    convertDispatch(cloneDelete(ids));

  const convertOpen: types.convertOpenFc = (props) =>
    convertDispatch(convertOpenAction(props));

  const prefixCalc: types.prefixCalcFc = (props) =>
    convertDispatch(prefixCalcAction(props));

  const variableCalc: types.variableCalcFc = (props) =>
    convertDispatch(variableCalcAction(props));

  return {
    convertState,
    convertCalc,
    convertReset,
    copyCalc,
    copyAdd,
    copyDelete,
    convertOpen,
    prefixCalc,
    variableCalc,
  };
};

export default useConvert;
