import { Blocks, BlockType, Block } from '../blocks/Type';
import Convert from './convert';

export const unitConvert = (block: Block): Block => {
  const convertResult = Convert(block.u[0]);
  let value = block.v;
  if (convertResult && convertResult[0] && convertResult[1]) {
    if (convertResult[0].includes('value')) {
      convertResult[0] = convertResult[0].replace('value', block.v);
      value = '1';
    }

    return {
      v: `${value}*(${convertResult[0]})`,
      u: convertResult[1],
      t: block.t,
    };
  }

  return block;
};

const ConvertBlocks = (blocks: Blocks): Blocks => {
  return blocks.map(
    (block: Block): Block => {
      if (block.t === BlockType.unit || block.t === BlockType.funcUnit) {
        return unitConvert(block);
      }

      return block;
    },
  );
};

export default ConvertBlocks;

// const blocks: Blocks = [
//   { v: '123', u: ['cm/s4'], t: 'unit' },
//   { v: '(', u: ['('], t: 'ps' },
//   { v: ')', u: [')'], t: 'ps' },
//   { v: '*', u: ['*'], t: 'ao' },
//   { v: '200', u: [''], t: 'val' },
// ];

// console.log(ConvertBlocks(blocks));
