import { BlockStep, BlockType } from './Type';

export const numberCheck = (evalStr: string): boolean => {
  if (Number(evalStr) || evalStr === '0' || evalStr === '.') {
    return true;
  }

  return false;
};

export const addBlockFactorial: BlockStep = (inputFunc, i = 0, inputBlock) => {
  const count = i;
  const evalStr = inputFunc.charAt(count);

  if (inputFunc.length === count || !numberCheck(evalStr)) {
    return {
      i: count,
      block: { v: inputBlock?.v || '', u: [], t: BlockType.ao },
    };
  }

  return addBlockFactorial(inputFunc, count + 1, {
    v: (inputBlock?.v || '') + evalStr,
    u: [],
    t: BlockType.ao,
  });
};

export const addBlockNum: BlockStep = (
  inputFunc = '',
  i = 0,
  inputBlock = { v: '', u: [], t: BlockType.na },
  unitFlg = false,
  parFlg = false,
) => {
  const block = inputBlock;
  let count = i;
  count += 1;
  if (inputFunc.length === count) {
    return { i: count, block };
  }
  const evalStr = inputFunc.charAt(count);
  if (numberCheck(evalStr) && !unitFlg) {
    block.v += evalStr;

    return addBlockNum(inputFunc, count, block, false, parFlg);
  }

  if (['+', '-', '^'].includes(evalStr)) {
    return { i: count, block };
  }

  if (['('].includes(evalStr)) {
    if (!numberCheck(inputFunc.charAt(i + 1))) {
      block.u = [(block.u[0] ? block.u[0] : '') + evalStr];

      return addBlockNum(inputFunc, count, block, true, true);
    }

    return { i: count, block };
  }

  if ([')'].includes(evalStr)) {
    if (parFlg) {
      block.u[0] += evalStr;
      count += 1;
    }

    return { i: count, block };
  }

  if (['*', '/'].includes(evalStr)) {
    const nextEvalStr = inputFunc.charAt(count + 1);
    if (numberCheck(nextEvalStr)) {
      return { i: count, block };
    }

    if (nextEvalStr === '(' && numberCheck(inputFunc.charAt(count + 2))) {
      return { i: count, block };
    }

    block.u = [(block.u[0] ? block.u[0] : '') + evalStr];

    return addBlockNum(inputFunc, count, block);
  }
  block.u = [(block.u[0] ? block.u[0] : '') + evalStr];
  block.t = BlockType.unit;

  return addBlockNum(inputFunc, count, block, true, parFlg);
};

export const addBlockFunc: BlockStep = (
  inputFunc = '',
  i = 0,
  inputBlock = { v: '', u: [], t: BlockType.func },
) => {
  const block = inputBlock;
  let count = i;
  if (inputFunc.length === count) {
    return { i: count, block };
  }
  count += 1;
  const evalStr = inputFunc.charAt(count);
  if (['+', '-', '*', '/'].includes(evalStr)) {
    return { i: count, block: { v: block.v, u: [], t: BlockType.func } };
  }
  block.v += evalStr;

  return addBlockFunc(inputFunc, count, block);
};
