const colors = (num: number): string => {
  const colorNumber = [
    '#42A5F5',
    '#5C6BC0',
    '#7E57C2',
    '#EF5350',
    '#26C6DA',
    '#26A69A',
    '#66BB6A',
    '#FFA726',
    '#FF7043',
    '#2196f3',
  ];

  return colorNumber[Number(num.toString().slice(-1))];
};

export default colors;
