import React, { FC, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot';
import InputTitle from '../atom/InputTitle';
import { PropsFc } from '../atom/InputButton';
import UnitsModal from './UnitsModal';
import UnitBtnDisplay from './UnitsBtnDisplay';

const UnitsInput: FC<PropsFc> = (props) => {
  const { inputSupport } = props;
  const [unitState, unitChange] = useState('Length');
  const [open, openChange] = useState(false);

  return (
    <Grow in>
      <Paper style={{ width: '100%', margin: 10 }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <InputTitle title="units">
            <ScatterPlotIcon fontSize="small" />
          </InputTitle>
        </div>
        <UnitsModal {...{ unitState, unitChange, open, openChange }} />
        <UnitBtnDisplay kind={unitState} {...{ inputSupport }} />
      </Paper>
    </Grow>
  );
};

export default UnitsInput;
