import React, { FC } from 'react';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Box from '@material-ui/core/Box';
import CachedIcon from '@material-ui/icons/Cached';
import { modalState } from 'Hooks/modalRecoils';
import { useRecoilState } from 'recoil';
import ClipBordCopy from '../atom/ClipBordCopy';
import { convertTypes } from '../../Hooks/convertTypes';
import useStyles from '../templates/UseStyle';
import colors from '../templates/colors';

type Props = { convertState: convertTypes };

const ConvertResults: FC<Props> = (props) => {
  const { convertState } = props;
  const classes = useStyles();
  const [modal, _setModal] = useRecoilState(modalState);
  const answerindex = modal.answer ? 20 : 0;

  return (
    <Paper
      elevation={3}
      className={classes.resultarea}
      style={{ zIndex: answerindex }}
    >
      {convertState.map(([result, _cloneResults]) => (
        <Slide in direction="right" key={result.id.toString()}>
          <Card
            className={classes.result}
            style={{
              position: 'relative',
              borderLeft: `solid 3px ${colors(result.id)}`,
              display: 'flex',
              alignItems: 'center',
              zIndex: answerindex,
            }}
            key={result.id.toString()}
          >
            <Box flexGrow={1} style={{ wordBreak: 'break-all' }}>
              <span style={{ fontSize: 15 }}>{result.value || 0}</span>
              <span style={{ marginLeft: 2, fontSize: 13 }}>{result.unit}</span>
            </Box>
            <Box
              display="flex"
              width="50%"
              alignItems="center"
              style={{ wordBreak: 'break-all' }}
            >
              <CachedIcon
                fontSize="small"
                style={{ marginRight: 10, color: '#606c76' }}
              />
              <Typography
                className={classes.convertformula}
                style={{ lineHeight: 1.1 }}
              >
                {result.convertStr}
              </Typography>
            </Box>
            <Box width={40}>
              <ClipBordCopy
                text={`${result.input}=${(result.value || 0).toString()}${
                  result.unit || ''
                }`}
                style={{ padding: 5 }}
              />
            </Box>
          </Card>
        </Slide>
      ))}
    </Paper>
  );
};

export default ConvertResults;
