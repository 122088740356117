import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

type Props = { title: string };
const InputTitle: FC<Props> = (props) => {
  const { children, title } = props;

  return (
    <Box
      display="inline-block"
      paddingTop={1}
      paddingRight={1}
      color="text.secondary"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {children}
        <Typography style={{ marginLeft: 5 }} variant="caption">
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

export default InputTitle;
