import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DetailsIcon from '@material-ui/icons/Details';
import CloseIcon from '@material-ui/icons/Close';
import { modalState } from 'Hooks/modalRecoils';
import { useRecoilState } from 'recoil';

import useStyles from './UseStyle';
import { BlockType } from '../../logic/modules/blocks/Type';
import EachInputAdd from '../molecules/EachInputAdd';
import ConvertVariables from '../molecules/ConvertVariables';
import PrefixSelect from '../molecules/PrefixSelect';
import SIunitAlter from '../molecules/SIunitAlter';
import * as types from '../../Hooks/convertTypes';

type Props = {
  resultIndex: number;
  copy: types.copy;
  copyIndex: number;
  copyCalc: types.copyCalcFc;
  copyAdd: types.copyAddFc;
  copyDelete: types.copyDeleteFc;
  convertOpen: types.convertOpenFc;
  prefixCalc: types.prefixCalcFc;
  variableCalc: types.variableCalcFc;
  kind: string | undefined;
};

const EachInputForm: FC<Props> = (props) => {
  const classes = useStyles();
  const {
    copy,
    copyCalc,
    resultIndex,
    copyIndex,
    copyAdd,
    copyDelete,
    prefixCalc,
    kind,
    convertOpen,
    variableCalc,
  } = props;

  const isCloneCopy = copyIndex !== 0;

  const [modal, _setModal] = useRecoilState(modalState);
  const cloneindex = modal.clone ? 20 : 0;

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    resultId: number,
    copyId: number,
    blockIndex: number,
  ) => {
    e.preventDefault();

    copyCalc({
      resultIndex: resultId,
      copyIndex: copyId,
      blockIndex,
      inputValue: Number(e.target.value) || 0,
    });
  };

  const clickOpen = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    resultId: number,
    copyId: number,
    openCheck: boolean,
  ) => {
    e.preventDefault();
    convertOpen({
      resultIndex: resultId,
      copyIndex: copyId,
      isOpen: openCheck,
    });
  };

  return (
    <>
      {isCloneCopy ? <div className={classes.eachSpace} /> : <></>}
      <Card style={{ zIndex: cloneindex, position: 'relative' }}>
        <Grid container>
          <Grid item xs={6} className={classes.cardRoot}>
            <EachInputAdd
              {...{ resultIndex, copyIndex, copyAdd, copyDelete }}
            />

            <div className={classes.eachinput}>
              {copy.blocks?.map((block, blockIndex) => (
                <div
                  className={classes.eachInputValue}
                  key={`${block.t}-${blockIndex.toString()}`}
                >
                  {block.t === BlockType.val || block.t === BlockType.unit ? (
                    <>
                      <div
                        style={{
                          display: 'inline-block',
                          width: `${block.v.length + 1.3}em`,
                        }}
                      >
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          value={block.v}
                          onChange={(e) =>
                            handleChange(e, resultIndex, copyIndex, blockIndex)
                          }
                          size="small"
                          fullWidth
                        />
                      </div>
                      <span style={{ marginLeft: 5 }}>{block.u}</span>
                    </>
                  ) : (
                    <>
                      {block.v}
                      {block.t === BlockType.funcUnit ? block.u : ''}
                    </>
                  )}
                </div>
              ))}
            </div>
          </Grid>
          <Grid item xs={6} className={classes.cardRoot}>
            <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
              <span style={{ marginRight: 10 }}>=</span>
              <span style={{ flexGrow: 1 }}>
                {copy.convertValue || copy.prefixValue || copy.value}
              </span>
              <span style={{ marginLeft: 5, fontSize: 14, flexGrow: 1 }}>
                {!copy.convertValue ? (
                  <PrefixSelect
                    {...{
                      resultIndex,
                      copyIndex,
                      prefixCalc,
                      prefix: copy.prefix,
                    }}
                  />
                ) : (
                  <></>
                )}

                {copy.convertUnit?.split(',')[0] || (
                  <SIunitAlter unit={copy.unit} kind={kind} />
                )}
              </span>
            </div>
            <div className={classes.flex1}>
              {kind ? (
                <Button
                  onClick={(e) =>
                    clickOpen(e, resultIndex, copyIndex, !copy.isOpen)
                  }
                  variant="outlined"
                  color="primary"
                  startIcon={copy.isOpen ? <CloseIcon /> : <DetailsIcon />}
                >
                  {kind}
                </Button>
              ) : (
                <></>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4} />
          <Grid item xs={8}>
            {kind && copy.isOpen ? (
              <ConvertVariables
                {...{ resultIndex, copyIndex, variableCalc }}
                value={copy.value}
                kind={kind}
              />
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default EachInputForm;
