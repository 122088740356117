import React, { FC } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

type Props = { title: string };
export type PropsFc = {
  inputSupport: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    arg: string,
    count?: number,
  ) => void;
};
const AnsButton: FC<Props & PropsFc> = (props) => {
  const { children, title, inputSupport } = props;

  return (
    <Tooltip arrow title="PREVIOUS ANSWER">
      <IconButton onClick={(e) => inputSupport(e, title)}>
        {children}
      </IconButton>
    </Tooltip>
  );
};

export default AnsButton;
