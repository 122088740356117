import { Blocks } from '../blocks/Type';

const unitMultiply = (blocks: Blocks): { [key: string]: number } => {
  const sumUnit: { [key: string]: number } = {};
  const PNLock = [[true, true]];
  blocks.forEach((block) => {
    block.u.forEach((blockUnit) => {
      const [aoLast, aoLock] = PNLock[PNLock.length - 1] || PNLock[0];
      switch (blockUnit) {
        case '(':
          PNLock.push([aoLast, true]);
          break;
        case ')':
          if (PNLock.length > 1) PNLock.pop();
          while (PNLock.length > 1 && !PNLock[PNLock.length - 1][1]) {
            PNLock.pop();
          }
          break;
        case '/':
          PNLock.push([!aoLast, false]);
          break;
        case '*':
          break;
        default: {
          const regVal = /(\D+)(-?\d+)?/i.exec(blockUnit);
          const unitReg = regVal ? regVal[1].toString() : '';
          const mulVal = regVal ? Number(regVal[2]) || 1 : 1;

          if (!sumUnit[unitReg]) {
            sumUnit[unitReg] = aoLast ? mulVal : -mulVal;
          } else if (aoLast) {
            sumUnit[unitReg] += mulVal;
          } else {
            sumUnit[unitReg] -= mulVal;
          }

          if (!aoLock) {
            PNLock.pop();
          }
        }
      }
      // console.log(blockUnit, PNLock);
      // console.log(sumUnit);
    });
  });

  return sumUnit;
};

export default unitMultiply;
