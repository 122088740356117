const unitSortStr = (sumSortUnit: [string, number][]): string => {
  if (!sumSortUnit[0]) {
    return '';
  }
  let minusLock = false;
  let initalTurn = true;
  const joinVal = sumSortUnit.reduce((sumUnitStr: string, a): string => {
    let unitStr = sumUnitStr;
    let multiplication = '*';
    if (initalTurn) {
      multiplication = '';
      initalTurn = false;
    }
    if (Math.sign(Number(a[1])) === -1) {
      if (!minusLock) {
        if (unitStr !== '(') {
          unitStr += ')/(';
        } else if (unitStr === '(') {
          unitStr = '/(';
        }
        minusLock = true;
        multiplication = '';
      }
    }

    return (
      unitStr +
      multiplication +
      (Math.abs(a[1]) === 1 ? a[0] : a[0] + String(Math.abs(a[1])))
    );
  }, '(');

  return `${joinVal})`;
};

export default unitSortStr;
