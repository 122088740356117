import React, { FC } from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Paper from '@material-ui/core/Paper';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import LinkIcon from '@material-ui/icons/Link';
import { useHistory } from 'react-router-dom';
import TextFormatIcon from '@material-ui/icons/TextFormat';
import { modalState } from 'Hooks/modalRecoils';
import { useRecoilState } from 'recoil';
import KeyClickButton from '../molecules/KeyClickButton';
import useStyles from '../templates/UseStyle';
import KeyFormButtons from '../molecules/KeyFormButtons';
import AnsButton from '../atom/AnsButton';
import CopyToClipBoard from '../atom/ClipBordCopy';

type Props = {
  inputRef: React.RefObject<HTMLTextAreaElement>;
  handleSubmit: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleClick: (e: React.MouseEvent<HTMLTextAreaElement, MouseEvent>) => void;
  inputState: {
    input: string;
    selection: number;
  };
  inputChange: React.Dispatch<
    React.SetStateAction<{
      input: string;
      selection: number;
    }>
  >;
  convertReset: () => void;
  keyChange: React.Dispatch<React.SetStateAction<number[]>>;
  keyState: number[];
  inputSupport: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    addStr: string,
    count?: number,
  ) => void;
};

const TextInputArea: FC<Props> = (props) => {
  const {
    inputRef,
    handleSubmit,
    handleClick,
    inputState,
    inputChange,
    convertReset,
    keyState,
    keyChange,
    inputSupport,
  } = props;

  const history = useHistory();
  const classes = useStyles();
  const [modal, _setModal] = useRecoilState(modalState);
  const inputindex = modal.textInput ? 20 : 0;

  return (
    <Paper
      elevation={3}
      style={{
        position: 'relative',
        paddingBottom: 25,
        zIndex: inputindex,
      }}
    >
      <TextareaAutosize
        ref={inputRef}
        onChange={handleSubmit}
        onClick={handleClick}
        aria-label="minimum height"
        rowsMin={3}
        placeholder="20m/3s&#13;&#10;new line can be used"
        id="outlined-multiline-static"
        className={classes.inputarea}
        value={inputState.input}
      />
      <div style={{ position: 'absolute', top: 5, right: 10 }}>
        <KeyClickButton {...{ inputChange, convertReset, inputRef }}>
          <HighlightOffIcon />
        </KeyClickButton>
      </div>
      <div style={{ position: 'absolute', top: 50, right: 10 }}>
        <AnsButton title="Ans" {...{ inputSupport }}>
          <TextFormatIcon />
        </AnsButton>
      </div>
      <div style={{ position: 'absolute', bottom: 5, left: 10 }}>
        <KeyFormButtons
          {...{
            keyState,
            keyChange,
            inputSupport,
            inputChange,
            convertReset,
            inputRef,
          }}
        />
      </div>
      <div style={{ position: 'absolute', bottom: 5, right: 12 }}>
        <CopyToClipBoard
          text={`${window.location.host}/${history.location.search}`}
          tooltip="URL COPIE"
        >
          <LinkIcon />
        </CopyToClipBoard>
      </div>
    </Paper>
  );
};

export default TextInputArea;
