import { Block, BlockType } from '../blocks/Type';
import mathCalc from '../converts/math';

const functions = (
  func: string,
  previousValueUnit?: { value?: number; unit?: string },
): Block | undefined => {
  const evalStr = func.toUpperCase();
  switch (true) {
    case /ANS/.test(evalStr):
      return {
        v: previousValueUnit?.value?.toString() || '0',
        u: previousValueUnit?.unit ? [previousValueUnit?.unit] : [],
        t: previousValueUnit?.unit ? BlockType.funcUnit : BlockType.funcVal,
      };
    case /[SIN|COS|TAN]\(.+\)/.test(evalStr): {
      const argNumber = /(SIN|COS|TAN)\((.+)\)/.exec(evalStr);
      let newStr = func;
      if (argNumber && Number(argNumber[2])) {
        newStr = `${argNumber[1]}(${argNumber[2]} deg)`;
      }

      return {
        v: newStr.toLowerCase(),
        u: [],
        t: BlockType.funcVal,
      };
    }
    case /π/.test(evalStr):
      return {
        v: 'pi',
        u: [],
        t: BlockType.funcVal,
      };

    default:
  }
  if (mathCalc([func.toLowerCase()])) {
    return {
      v: func.toLowerCase(),
      u: [],
      t: BlockType.funcVal,
    };
  }

  return undefined;
};

export default functions;
