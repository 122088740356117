import React, { FC } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import Calculator from 'containers/templates/Calculator';
import Subscription from 'containers/templates/Subscription';
import NavBar from 'containers/organisms/NavBar';
import Footer from 'containers/organisms/Footer';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import ShareInfor from 'containers/templates/ShareInfor';
import Introduce from 'containers/templates/Introduce';
import ScrollToTop from 'containers/templates/ScrollTop';
import TermService from 'containers/templates/TermService';
import theme from './theme';

import './App.css';

const App: FC = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <ScrollToTop />
        <NavBar />

        <Route exact path="/" component={Calculator} />
        <Route exact path="/Subscription" component={Subscription} />
        <Route exact path="/termofservice" component={TermService} />
        <ShareInfor />
        <Introduce />
        <Footer />
      </BrowserRouter>
    </MuiThemeProvider>
  );
};

export default App;
