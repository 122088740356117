import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';

import * as serviceWorker from './serviceWorker';
import App from './App';
import './index.css';

ReactDOM.render(
  <RecoilRoot>
    <App />
  </RecoilRoot>,
  document.getElementById('root') as HTMLElement,
);

serviceWorker.unregister();
