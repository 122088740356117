import React, { FC, useState, useEffect } from 'react';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import IconButton from '@material-ui/core/IconButton';
import { SiAlts } from '../../logic/modules/units/UnitExport';

type Props = { unit: string; kind: string | undefined };

const SIunitAlter: FC<Props> = (props) => {
  const { unit, kind } = props;
  const initialState =
    kind && SiAlts[kind] ? SiAlts[kind].concat([unit]) : [unit];

  const [state, stateChange] = useState(initialState);
  const [num, numChange] = useState(0);

  const Rclick = () => {
    numChange((prev) => prev + 1);
  };
  const Lclick = () => {
    numChange((prev) => prev - 1);
  };

  useEffect(() => {
    stateChange(kind && SiAlts[kind] ? SiAlts[kind].concat([unit]) : [unit]);
  }, [unit, kind]);

  return (
    <span style={{ marginLeft: 10 }}>
      {num !== 0 ? (
        <IconButton onClick={Lclick} style={{ padding: 3 }}>
          <ArrowLeftIcon />
        </IconButton>
      ) : (
        <></>
      )}
      {state[num]}
      {num !== state.length - 1 ? (
        <IconButton onClick={Rclick} style={{ padding: 3 }}>
          <ArrowRightIcon />
        </IconButton>
      ) : (
        <></>
      )}
    </span>
  );
};

export default SIunitAlter;
