type BlockType = typeof BlockType[keyof typeof BlockType];

export type Block = {
  v: string;
  u: string[];
  t: BlockType;
};

export const BlockType = {
  val: 'val',
  unit: 'unit',
  ao: 'ao',
  ps: 'ps',
  func: 'func',
  funcUnit: 'funcUnit',
  funcVal: 'funcVal',
  na: 'na',
} as const;

export type Blocks = Block[];

export type BlockCycle = {
  (
    inpuutFunc: string,
    blocks?: Blocks,
    i?: number,
    previousValueUnit?: { value?: number; unit?: string },
    ansInclude?: boolean,
  ): {
    blocks: Blocks;
    inputFunc: string;
    i: number;
    ansInclude: boolean;
  };
};

export type BlockStep = {
  (
    inpuutFunc: string,
    i?: number,
    block?: Block,
    unit?: boolean,
    parFlg?: boolean,
  ): {
    block: Block;
    i: number;
  };
};
