import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import UnitButton, { PropsFc } from '../atom/UnitButton';
import {
  SiBase,
  SiDer,
  SiAlts,
  Variable,
} from '../../logic/modules/units/UnitExport';

type Props = {
  kind: string;
};

const allUnits = { ...SiBase, ...SiDer };

const UnitBtnDisplay: FC<Props & PropsFc> = (props) => {
  const { kind, inputSupport } = props;

  return (
    <Box p={3}>
      <tr>
        <UnitButton num={allUnits[kind]} {...{ inputSupport }} />
        {SiAlts[kind] ? (
          SiAlts[kind].map((unit) => (
            <UnitButton num={unit} {...{ inputSupport }} />
          ))
        ) : (
          <></>
        )}
      </tr>
      {Variable[kind] ? (
        <tr>
          {Object.keys(Variable[kind]).map((unit) => (
            <UnitButton
              num={unit}
              value={unit.split(',')[0]}
              {...{ inputSupport }}
              key={unit}
            />
          ))}
        </tr>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default UnitBtnDisplay;
