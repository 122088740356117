import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import unitConvert from '../../logic/modules/unitCalc/unitConvert';
import * as types from '../../Hooks/convertTypes';

type Props = {
  resultIndex: number;
  copyIndex: number;
  value: number;
  kind: string;
  variableCalc: types.variableCalcFc;
};
const ConvertVariables: FC<Props> = (props) => {
  const { resultIndex, copyIndex, value, kind, variableCalc } = props;
  const convertResults = unitConvert(value, '', kind);

  const handleClick = (convertUnit: string) => {
    variableCalc({ resultIndex, copyIndex, convertUnit });

    return false;
  };

  if (convertResults) {
    return (
      <Paper elevation={2} style={{ margin: '20px 10px', padding: 10 }}>
        {convertResults[1].map(([unit, convertValue]) => (
          <Button
            variant="outlined"
            key={unit}
            style={{ minWidth: '48%', margin: 5, padding: 10 }}
            onClick={() => handleClick(unit)}
          >
            <div style={{ display: 'flex', width: '100%' }}>
              <span style={{ flexGrow: 2, textAlign: 'left', width: '60%' }}>
                {convertValue}
              </span>
              <span
                style={{
                  marginLeft: 5,
                  fontWeight: 'bold',
                }}
              >
                {unit}
              </span>
            </div>
          </Button>
        ))}
      </Paper>
    );
  }

  return <></>;
};

export default ConvertVariables;
