import { Variable, SiDer, SiBase } from '../units/UnitExport';
import convertType from './Type';
import { convertSIs } from './convertSI';
import convertTemp from './convertTemp';

const convertVariable = (unit: string): convertType => {
  let evalUnit: string | undefined;
  let evalValUnit: string[] | undefined;
  const findVal = Object.entries(Variable).find((variables) => {
    return Object.entries(variables[1]).find((variable) => {
      evalValUnit = variable;
      evalUnit = variable[0]
        .split(', ')
        .find((vari) => vari.toUpperCase() === unit.toUpperCase());

      return evalUnit;
    });
  });

  if (findVal && evalValUnit) {
    if (findVal[0] === 'Tempreture') {
      return convertTemp(unit);
    }

    return convertSIs(
      String(evalValUnit[1]),
      String(SiDer[findVal[0]] || SiBase[findVal[0]]),
    );
  }

  return null;
};

export default convertVariable;

// console.log(convertVariable('feet'));
// console.log(convertVariable('"'));
