import React, { FC } from 'react';

const Youtube: FC = () => {
  return (
    <iframe
      title="youtube"
      width="560"
      height="315"
      src="https://www.youtube.com/embed/uAM_mNUa2J8"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
};

export default Youtube;
