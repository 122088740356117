import React, { FC } from 'react';
import Button from '@material-ui/core/Button';

type Props = { num: number | string; value?: number | string; count?: number };
export type PropsFc = {
  inputSupport: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    arg: string,
    count?: number,
  ) => void;
};
const UnitButton: FC<Props & PropsFc> = (props) => {
  const { children, value, num, count, inputSupport } = props;

  return (
    <Button
      style={{
        border: '0.5px solid #ccc',
        borderRadius: 5,
        margin: 2,
      }}
      onClick={(e) =>
        inputSupport(e, value?.toString() || num.toString(), count)
      }
    >
      {num}
      {children}
    </Button>
  );
};

export default UnitButton;
