import MetricPrefixTmp from './metric_prefix.json';
import SiBaseTmp from './si_base.json';
import SiDerTmp from './si_derived_unit.json';
import SiAltsTmp from './si_alter_unit.json';
import VariableTmp from './variable_unit.json';
import VariableReTmp from './variable_re.json';

type exType = {
  [key: string]: string;
};
type exxType = {
  [key: string]: string[];
};

type exxxType = {
  [key: string]: {
    [key: string]: string;
  };
};

export const MetricPrefix: exType = MetricPrefixTmp;
export const SiBase: exType = SiBaseTmp;
export const SiDer: exType = SiDerTmp;
export const SiAlts: exxType = SiAltsTmp;
export const Variable: exxxType = VariableTmp;
export const VariableRe: exxxType = VariableReTmp;
