import React, { FC } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SiBase, SiDer } from '../../logic/modules/units/UnitExport';

type Props = {
  unitState: string;
  unitChange: React.Dispatch<React.SetStateAction<string>>;
  open: boolean;
  openChange: React.Dispatch<React.SetStateAction<boolean>>;
};

const allUnits = { ...SiBase, ...SiDer };

const UnitModal: FC<Props> = (props) => {
  const { unitState, unitChange, open, openChange } = props;
  const btnClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    kind: string,
  ) => {
    e.preventDefault();
    unitChange(kind);
    openChange(false);
  };

  return (
    <Accordion
      expanded={open}
      onChange={() => openChange(!open)}
      style={{
        boxShadow: 'none',
        borderBottom: 'solid 0.5px #ccc',
        borderTop: 'solid 0px #ccc',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="button">{unitState}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography style={{ wordBreak: 'break-all' }}>
          {Object.keys(allUnits).map((kind) => (
            <Button style={{ width: 150 }} onClick={(e) => btnClick(e, kind)}>
              {kind}
            </Button>
          ))}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default UnitModal;
