/* eslint no-cond-assign: 2 */

import { MetricPrefix } from '../units/UnitExport';
import { SiRegExp, getRegExp, AoArray } from '../aoRegExps';
import convertType from './Type';

const convertSI = (unit: string): convertType => {
  const matchArray: Array<[string, string, string]> = [];

  const reg = SiRegExp('g');
  let resultArray: RegExpExecArray | null;
  while ((resultArray = reg.exec(unit)) !== null && resultArray[0] !== '') {
    matchArray.push([
      resultArray[1] ? resultArray[1] : '',
      resultArray[2],
      resultArray[3] ? resultArray[3] : '',
    ]);
  }

  if (
    matchArray.reduce(
      (previousValue, currentValue) =>
        previousValue + currentValue[0] + currentValue[1] + currentValue[2],
      '',
    ) !== unit
  ) {
    return null;
  }

  type matchType = [string, string[]];

  return matchArray
    .map((siM) => {
      const unitSi = siM[1] === 'g' ? 'kg' : siM[1];
      const prefixNumber = siM[2];

      let metric = '1';
      if (siM[0]) {
        metric = MetricPrefix[siM[0]];
        if (unitSi === 'kg') {
          metric += `/${MetricPrefix.k}`;
        }
      } else if (unitSi === 'kg') {
        metric += `/${MetricPrefix.k}`;
      }

      return [
        prefixNumber ? `(${metric}^${prefixNumber})` : `(${metric})`,
        unitSi + prefixNumber,
      ];
    })
    .reduce(
      (previousValue: matchType, currentValue, index): matchType => {
        return [
          `${previousValue[0]}${index === 0 ? '' : '*'}${currentValue[0]}`,
          previousValue[1].concat(currentValue[1]),
        ];
      },
      ['', []],
    );
};

export const convertSIs = (value: string, units: string): convertType => {
  let unitVal = value;
  const unitsArray: string[] = [];
  units.split(getRegExp(true, ...AoArray, '(', ')')).forEach((unit) => {
    if (!unit) {
      return;
    }
    if (AoArray.includes(unit)) {
      unitVal += unit;
      unitsArray.push(unit);
    } else if (['(', ')'].includes(unit)) {
      unitVal += unit;
      unitsArray.push(unit);
    } else {
      const siUnit = convertSI(unit);
      if (siUnit) {
        const last = unitsArray.slice(-1)[0];
        const ao = AoArray.concat(['(', ')']).includes(last) ? '' : '*';
        unitVal = `${unitVal}${ao}${siUnit[0]}`;
        unitsArray.push(...siUnit[1]);
      } else {
        unitsArray.push(unit);
      }
    }
  });

  return [unitVal, unitsArray];
};

export default convertSI;

// SI test
// console.log(convertSI('kgm'));
// console.log(convertSI('cm'));

// SIs test
// console.log(convertSIs('1', 'kg/cm2'));
