import React, { FC } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import IconButton from '@material-ui/core/IconButton';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Youtube from '../molecules/Youtube';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);
const OpenYoutube: FC = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <div>
      <IconButton color="inherit" onClick={handleToggle}>
        <YouTubeIcon />
      </IconButton>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <Youtube />
      </Backdrop>
    </div>
  );
};

export default OpenYoutube;
