import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import NumberInput from '../molecules/NumberInput';
import OperatorInput from '../molecules/OperatorInput';
import FunctionInput from '../molecules/FunctionInput';
import UnitsInput from '../molecules/UnitsInput';

type Props = {
  inputChange: React.Dispatch<
    React.SetStateAction<{ input: string; selection: number }>
  >;
  inputRef: React.RefObject<HTMLTextAreaElement>;
  convertReset: () => void;
  inputSupport: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    addStr: string,
    count?: number,
  ) => void;
  keyState: number[];
  keyChange: React.Dispatch<React.SetStateAction<number[]>>;
};

const KeyForm: FC<Props> = (props) => {
  const { inputSupport, keyState } = props;

  return (
    <Box display="flex" flexWrap="wrap" marginTop="5" width="100%">
      {keyState.includes(3) ? <NumberInput {...{ inputSupport }} /> : <></>}
      {keyState.includes(4) ? <OperatorInput {...{ inputSupport }} /> : <></>}
      {keyState.includes(5) ? <FunctionInput {...{ inputSupport }} /> : <></>}
      {keyState.includes(6) ? <UnitsInput {...{ inputSupport }} /> : <></>}
    </Box>
  );
};

export default KeyForm;
