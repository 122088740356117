import React, { FC, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { modalState, initialModal } from 'Hooks/modalRecoils';
import CancelIcon from '@material-ui/icons/Cancel';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import './Modal.css';
import { useHistory } from 'react-router-dom';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import * as types from '../../Hooks/convertTypes';

type Props = {
  inputSupport: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined,
    addStr: string,
    count?: number,
  ) => void;
  copyCalc: types.copyCalcFc;
  copyAdd: types.copyAddFc;
  convertReset: () => void;
  inputChange: React.Dispatch<
    React.SetStateAction<{
      input: string;
      selection: number;
    }>
  >;
};

const Modal: FC<Props> = (props) => {
  // const { inputSupport, copyAdd, copyCalc, convertReset, inputChange } = props;
  const { inputSupport, convertReset, inputChange } = props;
  const [modal, setModal] = useRecoilState(modalState);
  const [timers, timersSet] = useState<NodeJS.Timeout[]>([]);
  const history = useHistory();

  const handleClose = () => {
    convertReset();
    inputChange({ input: '', selection: 0 });
    history.push('/?formula=');
    timers.forEach((timerId) => {
      clearTimeout(timerId);
    });
    setModal(initialModal);
  };

  const modalCall = (s: number, fn: () => void) => {
    const timerId = setTimeout(() => {
      let modalBool = true;
      setModal((c) => {
        modalBool = c.modal;

        return c;
      });
      if (modalBool) fn();
    }, s);
    timersSet((c) => {
      c.push(timerId);

      return c;
    });
  };

  useEffect(() => {
    if (modal.modal) {
      convertReset();
      inputChange({ input: '', selection: 0 });

      // setModal((c) => ({ ...c, explainWord: 'Instruction will be started' }));
      // modalCall(1500, () => setModal((c) => ({ ...c, explain: false })));

      modalCall(0, () => {
        inputSupport(undefined, '1000');
        setModal((c) => ({
          ...c,
          textInput: true,
          explain: true,
          explainWord: 'Please input formula!!.',
        }));
      });

      modalCall(2500, () => {
        inputSupport(undefined, '1000/');
      });

      modalCall(3500, () => {
        inputSupport(undefined, '1000/20');
      });

      modalCall(4500, () => {
        inputSupport(undefined, '1000/20*100');
      });

      modalCall(6000, () => {
        inputSupport(undefined, '1000/20*100\n100cm/20s');
      });

      // add contents
      modalCall(8000, () => {
        setModal({
          ...initialModal,
          modal: true,
          enjoy: true,
        });
      });

      modalCall(9500, () => {
        handleClose();
        setModal(initialModal);
      });

      // modalCall(9000, () => {
      //   setModal({ ...initialModal, modal: true });
      // });

      // modalCall(5200, () => {
      //   setModal((c) => ({
      //     ...c,
      //     answer: true,
      //     textInput: false,
      //     explain: true,
      //     explainWord: 'You can check answer at right column.',
      //   }));
      // });

      // modalCall(7500, () => {
      //   setModal({ ...initialModal, modal: true });
      // });

      // modalCall(8000, () => {
      //   setModal((c) => ({
      //     ...c,
      //     answer: false,
      //     explain: true,
      //     explainWord: 'Formula is copied to below.',
      //     clone: true,
      //   }));
      // });

      // modalCall(10000, () => {
      //   copyAdd(0);
      //   setModal((c) => ({
      //     ...c,
      //     explainWord: 'Copied can increase by add button.',
      //   }));
      // });

      // modalCall(13500, () => {
      //   setModal((c) => ({
      //     ...c,
      //     explainWord: 'Each value can be changed and comparable.',
      //   }));
      //   copyCalc({
      //     resultIndex: 0,
      //     copyIndex: 1,
      //     blockIndex: 0,
      //     inputValue: 6000,
      //   });
      // });

      // modalCall(14500, () => {
      //   copyCalc({
      //     resultIndex: 0,
      //     copyIndex: 1,
      //     blockIndex: 2,
      //     inputValue: 3,
      //   });
      // });

      // modalCall(17000, () => {
      //   setModal({ ...initialModal, modal: true });
      //   inputSupport(undefined, '');
      // });

      // modalCall(17500, () => {
      //   setModal({
      //     ...initialModal,
      //     textInput: true,
      //     modal: true,
      //     answer: true,
      //   });
      //   inputSupport(undefined, '10*20');
      // });

      // modalCall(18000, () => {
      //   inputSupport(undefined, '10*20\n');
      // });

      // modalCall(19000, () => {
      //   inputSupport(undefined, '10*20\n100*20');
      //   setModal((c) => ({
      //     ...c,
      //     textInput: true,
      //     answer: true,
      //     explain: true,
      //     explainWord: 'Parallel calculation by enter can be used.',
      //   }));
      // });

      // modalCall(22000, () => {
      //   inputSupport(undefined, '10*20\n100*20\nAns-100');
      //   setModal((c) => ({
      //     ...c,
      //     textInput: true,
      //     answer: true,
      //     explain: true,
      //     explainWord: 'Previous answer can refer by [Ans] command.',
      //   }));
      // });

      // modalCall(26500, () => {
      //   setModal({ ...initialModal, modal: true });
      //   inputSupport(undefined, '');
      // });

      // modalCall(27000, () => {
      //   inputSupport(undefined, '100cm');
      //   setModal((c) => ({
      //     ...c,
      //     textInput: true,
      //     answer: true,
      //     explain: true,
      //     explainWord: 'Unit calculation is available.',
      //   }));
      // });

      // modalCall(28000, () => {
      //   inputSupport(undefined, '100cm\n');
      // });

      // modalCall(29000, () => {
      //   inputSupport(undefined, '100cm\n100cm/20s');
      // });

      // modalCall(31000, () => {
      //   inputSupport(undefined, '100cm\n100cm/20s\nans*10s');
      // });

      // modalCall(35000, () => {
      //   setModal({
      //     ...initialModal,
      //     modal: true,
      //     enjoy: true,
      //   });
      // });

      // modalCall(38000, () => {
      //   handleClose();
      //   setModal(initialModal);
      // });
    }
  }, [modal.modal]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {modal.modal ? (
        <div className="modal-overlay">
          {modal.explain ? (
            <Paper
              className="modal-explain"
              style={{ color: '#0d47a1', backgroundColor: '#e3f2fd' }}
            >
              <div style={{ margin: 'auto' }}>{modal.explainWord}</div>
            </Paper>
          ) : (
            <></>
          )}
          <Paper className="modal-close">
            <Button onClick={handleClose} className="modal-close-button">
              <CancelIcon />
              CLOSE INSTRUCTION
            </Button>
          </Paper>
        </div>
      ) : (
        <></>
      )}
      {modal.enjoy ? (
        <Paper className="modal-enjoy">
          Enjoy Calculation!! {'   '}
          <SentimentSatisfiedAltIcon color="primary" />
        </Paper>
      ) : (
        <></>
      )}
    </>
  );
};

export default Modal;
