import React, { FC, useState } from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
} from 'react-share';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Grow from '@material-ui/core/Grow';
import LinkIcon from '@material-ui/icons/Link';
import ShareIcon from '@material-ui/icons/Share';
import Tooltip from '@material-ui/core/Tooltip';
import BugReportIcon from '@material-ui/icons/BugReport';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { makeStyles, createStyles } from '@material-ui/core/styles';

import { useRecoilState } from 'recoil';
import { modalState } from 'Hooks/modalRecoils';
import CopyToClipBoard from '../atom/ClipBordCopy';
import { ReactComponent as Discord } from '../../images/Discord.svg';

export const iconClasses = makeStyles(() =>
  createStyles({
    shareicon: {
      marginLeft: 8,
    },
    balloon: {
      position: 'relative',
      display: 'flex',
    },
  }),
);

const ShareInfor: FC = () => {
  const history = useHistory();
  const url = `${window.location.host}/${history.location.search}`;
  const btStyle = { border: 'solid 1px #ccc', backgroundColor: '#fff' };
  const [open, openChange] = useState(false);
  const [openBug, openBugChange] = useState(false);
  const classes = iconClasses();
  const [_, setModal] = useRecoilState(modalState);

  return (
    <Grid
      container
      justify="center"
      style={{ marginTop: 100, marginBottom: 100 }}
    >
      <Grid item style={{ display: 'flex' }}>
        <CopyToClipBoard
          text={url}
          style={btStyle}
          tooltip="URL COPIE TO CLIPBORD"
        >
          <LinkIcon />
        </CopyToClipBoard>
        <div style={{ position: 'relative' }}>
          <Tooltip arrow placement="top" title="URL SHARE BUTTON">
            <IconButton
              style={{ ...btStyle, marginLeft: 20 }}
              onClick={() => openChange(!open)}
            >
              <ShareIcon color={open ? 'primary' : 'inherit'} />
            </IconButton>
          </Tooltip>
          <div style={{ position: 'absolute', top: 60, left: -60 }}>
            <Grow in={open}>
              <Grid item className={classes.balloon}>
                <TwitterShareButton url={url} className={classes.shareicon}>
                  <TwitterIcon round size={30} />
                </TwitterShareButton>
                <RedditShareButton url={url} className={classes.shareicon}>
                  <RedditIcon round size={30} />
                </RedditShareButton>

                <FacebookShareButton url={url} className={classes.shareicon}>
                  <FacebookIcon round size={30} />
                </FacebookShareButton>

                <EmailShareButton
                  subject="Calculation Share"
                  body={url}
                  url={url}
                  className={classes.shareicon}
                >
                  <EmailIcon round size={30} />
                </EmailShareButton>

                <LinkedinShareButton
                  title="Calculation Share"
                  source={url}
                  summary={url}
                  url={url}
                  className={classes.shareicon}
                >
                  <LinkedinIcon round size={30} />
                </LinkedinShareButton>
              </Grid>
            </Grow>
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          <Tooltip arrow placement="top" title="BUG REPORT">
            <IconButton
              style={{ ...btStyle, marginLeft: 20 }}
              onClick={() => openBugChange(!openBug)}
            >
              <BugReportIcon color={openBug ? 'primary' : 'inherit'} />
            </IconButton>
          </Tooltip>
          <div style={{ position: 'absolute', top: 60, left: -10 }}>
            <Grow in={openBug}>
              <Grid item className={classes.balloon}>
                <a
                  href="https://discord.gg/jwEBEynSZx"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Discord
                    style={{ height: 30, width: 30 }}
                    className={classes.shareicon}
                  />
                </a>
                <TwitterShareButton
                  url="https://twitter.com/intent/tweet?screen_name=FarEastAzy"
                  className={classes.shareicon}
                >
                  <TwitterIcon round size={30} />
                </TwitterShareButton>

                <EmailShareButton
                  subject="Calculation Share"
                  body={url}
                  url={url}
                  className={classes.shareicon}
                >
                  <EmailIcon round size={30} />
                </EmailShareButton>
              </Grid>
            </Grow>
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          <Tooltip arrow placement="top" title="INSTRUCTION START">
            <IconButton
              style={{ ...btStyle, marginLeft: 20 }}
              onClick={() =>
                setModal((c) => ({ ...c, modal: true, explain: true }))
              }
            >
              <HelpOutlineIcon />
            </IconButton>
          </Tooltip>
        </div>
      </Grid>
    </Grid>
  );
};

export default ShareInfor;
