import React, { FC } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { MetricPrefix } from '../../logic/modules/units/UnitExport';
import * as types from '../../Hooks/convertTypes';

type Prop = {
  prefix?: number;
  prefixCalc: types.prefixCalcFc;
  resultIndex: number;
  copyIndex: number;
};

const PrefixSelect: FC<Prop> = (props) => {
  const { prefix, resultIndex, copyIndex, prefixCalc } = props;
  const selectChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    e.preventDefault();
    prefixCalc({ resultIndex, copyIndex, prefix: Number(e.target.value) });
  };

  return (
    <Select
      defaultValue={prefix ?? MetricPrefix.da}
      style={{ marginLeft: 10 }}
      onChange={selectChange}
    >
      {Object.keys(MetricPrefix).map((metric) => (
        <MenuItem
          style={{ textAlign: 'center', fontSize: 14 }}
          value={metric === 'da' ? '1' : MetricPrefix[metric]}
          key={metric}
        >
          {metric === 'da' ? '' : metric}
        </MenuItem>
      ))}
    </Select>
  );
};

export default PrefixSelect;
