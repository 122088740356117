import { SiBase, MetricPrefix } from './units/UnitExport';

export const AoArray: string[] = ['+', '-', '*', '/'];

export const escapeRegExp = (inputStr: string): string => {
  return inputStr.replace(/[.*+?^=!:${}()|[\]\/\\]/g, '\\$&'); // eslint-disable-line
};

export const getRegExp = (
  regBool: boolean,
  ...args: string[]
): string | RegExp => {
  let regStr = '';
  args.forEach((arg) => {
    regStr += `${escapeRegExp(arg)}|`;
  });
  regStr = `(${regStr.slice(0, -1)})`;

  if (!regBool) {
    return regStr;
  }

  return new RegExp(regStr);
};

export const meticValues: string | RegExp = getRegExp(
  false,
  ...Object.keys(MetricPrefix),
);

export const SiRegExp = (regArg = ''): RegExp => {
  const siValues: string | RegExp = getRegExp(false, ...Object.values(SiBase));

  return new RegExp(
    `${String(meticValues)}?${String(siValues)}(-?[1-9]+)?`,
    regArg,
  );
};
