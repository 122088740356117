import React, { FC } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Azy from '../../images/azy.png';

const useClass = makeStyles(() =>
  createStyles({
    paper: {
      padding: 20,
      marginTop: 20,
      width: '100%',
    },
  }),
);

const Subscription: FC = () => {
  const classes = useClass();

  return (
    <Grid container style={{ maxWidth: 800, margin: 'auto' }}>
      <Paper className={classes.paper}>
        <Typography variant="h3" style={{ lineHeight: 2 }}>
          When I get 50 person pre registered, I&apos;m going to developing
          subscription function.
        </Typography>
        <Typography variant="h3" style={{ lineHeight: 2, marginTop: 20 }}>
          Please send{' '}
          <a
            href="https://forms.gle/xZdVpyLUbZhfPxA7A"
            target="_blank"
            rel="noreferrer"
          >
            Google from
          </a>
          , for pre register and get disconut (6 month free).
        </Typography>
        <Typography variant="h3" style={{ lineHeight: 2, marginTop: 20 }}>
          Subscription is planned as follows;
        </Typography>
        <Typography variant="h3" style={{ lineHeight: 2 }}>
          - 1USD/Month
        </Typography>
        <Typography variant="h3" style={{ lineHeight: 2 }}>
          - No advitisement
        </Typography>
        <Typography variant="h3" style={{ lineHeight: 2 }}>
          - Serve the history, and sync to other devise
        </Typography>
        <Typography variant="h3" style={{ lineHeight: 2 }}>
          - Custom unit
        </Typography>
        <Typography variant="h3" style={{ lineHeight: 2 }}>
          - Custom function
        </Typography>
        <Typography variant="h3" style={{ lineHeight: 2, marginTop: 20 }}>
          We can discuss what plan is our ideal for calculation at{' '}
          <a
            href="https://discord.gg/jwEBEynSZx"
            target="_blank"
            rel="noreferrer"
          >
            Discord
          </a>
        </Typography>
      </Paper>
      <Paper style={{ textAlign: 'center' }} className={classes.paper}>
        <h1>Subscription Pre Register is NOW</h1>
        <h1
          style={{
            border: 'solid 0.5px #ccc',
            borderRadius: 100,
            width: 200,
            margin: 'auto',
            padding: 10,
          }}
        >
          1/50
        </h1>
        <img
          src={Azy}
          alt="Azy logo"
          style={{ backgroundColor: '#2196f3', marginTop: 30 }}
        />
      </Paper>
    </Grid>
  );
};

export default Subscription;
