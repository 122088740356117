import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const Introduce: FC = () => {
  return (
    <section style={{ textAlign: 'center', marginTop: 40 }}>
      <Typography variant="h6">
        <Box m={1}>
          Solo Development by{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/fareastazy"
          >
            @FarEastAzy
          </a>
          .
        </Box>
        <Link to="/Subscription">
          <Box margin="auto">Interest SUBSCRIPTION?</Box>
        </Link>
      </Typography>
    </section>
  );
};

export default Introduce;
