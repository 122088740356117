import mathCalc from './modules/converts/math';
import { Blocks, BlockType } from './modules/blocks/Type';
import getEachBlock from './modules/blocks/getEachBlock';
import ConvertBlocks from './modules/converts/convertBlocks';
import unitCalc from './modules/unitCalc/unitCalc';
import { getUnitKind } from './modules/unitCalc/unitConvert';
import weightCorrection from './modules/units/weightArray';

export type calcType = {
  id: number;
  input: string;
  value?: number;
  unit?: string;
  kind?: string;
  blocks: Blocks;
  error: boolean;
  convertStr: string;
  ansInclude: boolean;
};

export const Calc = (
  inputFunc: string,
  id = 0,
  argBlocks: undefined | Blocks = undefined,
  previousValueUnit?: { value?: number; unit?: string },
): calcType => {
  const { blocks, ansInclude } = argBlocks
    ? { blocks: argBlocks, ansInclude: false }
    : getEachBlock(inputFunc, [], 0, previousValueUnit);

  const convertResult = ConvertBlocks(blocks);

  const convertValue = convertResult.reduce(
    (preVal, curVal) => preVal + curVal.v,
    '',
  );

  const convertStr = convertResult.reduce((s, a): string => {
    let ts: string;
    if (
      a.t === BlockType.val ||
      a.t === BlockType.unit ||
      a.t === BlockType.funcUnit ||
      a.t === BlockType.funcVal
    ) {
      try {
        ts = ((a.t === BlockType.funcVal) ? a.v : mathCalc([a.v])) + a.u.join(''); //eslint-disable-line
      } catch (e) {
        ts = 'error';
      }
    } else {
      ts = a.v;
    }

    return s + ts;
  }, '');

  const valueResult = convertValue !== '' ? mathCalc([convertValue]) : 0;
  const error = false; // errorS処理の見直しが必要

  const unitResult = unitCalc(convertResult);

  const kind = getUnitKind(unitResult);

  const { value, unit } = weightCorrection(kind, valueResult, unitResult);

  return {
    id,
    input: inputFunc,
    value,
    blocks,
    kind,
    unit,
    convertStr,
    error,
    ansInclude,
  };
};

export const convert = (value: number, siUnit: string): string => {
  return value.toString() + siUnit;
};
// console.log('input is : 200cm+20mm');
// console.log(Calc('200cm+20mm'));
// console.log(Calc('200cm+20kg'));

// eval-calculation.js eval-calculation.js は引数として与えられた計算式の文字列を評価し、計算結果となる数値を返します。
// https://gist.github.com/think49/54b074cab2145efddb48765652c74710
