import { convertSIs } from '../converts/convertSI';
import unitMultiply from './unitMultiply';
import unitSort from './unitSort';
import unitSortStr from './unitSortStr';
import { SiBase, SiDer, Variable, VariableRe } from '../units/UnitExport';
import { BlockType } from '../blocks/Type';
import mathCalc from '../converts/math';

const sortedUnit = (unit: string) => {
  const convertResult = convertSIs('1', unit) || ['', ['']];

  return unitSortStr(
    unitSort(
      unitMultiply([
        { v: convertResult[0], u: convertResult[1], t: BlockType.unit },
      ]),
    ),
  );
};

export const getUnitKind = (unit: string): string | undefined => {
  if (unit === '') return undefined;
  let unitKindResult: string | undefined;
  [SiBase, SiDer].find((baseUnit) => {
    const findResult = Object.entries(baseUnit).find(([_, siUnit]) => {
      return unit === sortedUnit(siUnit);
    });
    if (findResult) {
      [unitKindResult] = findResult;

      return true;
    }

    return false;
  });

  if (!unitKindResult) return undefined;

  return unitKindResult;
};

const unitConvertCalcResult = (value: number | string, valueUnit: string) => {
  return valueUnit.includes('value')
    ? mathCalc([valueUnit.replace('value', value.toString())])
    : mathCalc([value, '/(', valueUnit, ')']);
};

const unitConvert = (
  value: number,
  unit: string,
  kind?: string,
): [string, [string, number][]] | undefined => {
  if (unit === '' && kind === '') return undefined;

  const unitKind = kind || getUnitKind(unit);
  if (!unitKind || !Variable[unitKind]) return undefined;

  return [
    unitKind,
    Object.entries({ ...Variable, ...VariableRe }[unitKind]).map(
      (variableArr) => {
        return [variableArr[0], unitConvertCalcResult(value, variableArr[1])];
      },
    ),
  ];
};

export const singleUnitConvert = (
  siValue: number,
  unit: string,
  kind: string,
): number => {
  const variableFunc = Object.entries(
    { ...Variable, ...VariableRe }[kind],
  ).find(([unitName, _]) => {
    if (unitName === unit) return true;

    return false;
  });

  if (!variableFunc) return 0;

  return unitConvertCalcResult(siValue, variableFunc[1]);
};

// console.log(SiDer);
// console.log(unitConvert(1, '(m)'));
// console.log(unitConvert(1, '(m)/(s)'));

export default unitConvert;
