import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import { FacebookIcon, TwitterIcon } from 'react-share';
import { Link } from 'react-router-dom';
import { iconClasses } from '../templates/ShareInfor';
import { ReactComponent as Discord } from '../../images/Discord.svg';

const Footer: FC = () => {
  const classes = iconClasses();

  return (
    <footer>
      <section
        style={{ textAlign: 'center', backgroundColor: '#fff', padding: 40 }}
      >
        <Box p={2}>
          <Link to="/termofservice">Term of Service</Link>
        </Box>

        <Box>Join me</Box>
        <Box>
          {' '}
          <a
            href="https://twitter.com/fareastazy"
            target="_blank"
            rel="noreferrer"
            className={classes.shareicon}
          >
            <TwitterIcon round size={30} />
          </a>
          <a
            href="https://www.facebook.com/EastAzy"
            target="_blank"
            rel="noreferrer"
            className={classes.shareicon}
          >
            <FacebookIcon round size={30} />
          </a>
          <a
            href="https://discord.gg/jwEBEynSZx"
            target="_blank"
            rel="noreferrer"
          >
            <Discord
              style={{ height: 30, width: 30 }}
              className={classes.shareicon}
            />
          </a>
        </Box>
        <Box>Copy right(c) Azy- All rights reserved.</Box>
      </section>
    </footer>
  );
};

export default Footer;
