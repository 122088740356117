import React, { FC } from 'react';
import IconButton from '@material-ui/core/IconButton';

type Props = { num: number | string; value?: number | string; count?: number };
export type PropsFc = {
  inputSupport: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    arg: string,
    count?: number,
  ) => void;
};
const InputButton: FC<Props & PropsFc> = (props) => {
  const { children, value, num, count, inputSupport } = props;

  return (
    <IconButton
      style={{
        marginRight: 5,
        borderRadius: 30,
        minWidth: 36,
        border: 'solid 0.5px #ccc',
        fontSize: 10,
      }}
      onClick={(e) =>
        inputSupport(e, value?.toString() || num.toString(), count)
      }
    >
      {num}
      {children}
    </IconButton>
  );
};

export default InputButton;
