import { BlockCycle, BlockStep, BlockType } from './Type';
import {
  addBlockNum,
  addBlockFunc,
  numberCheck,
  addBlockFactorial,
} from './addBlock';
import functions from '../functions/functions';

const runEachStep: BlockStep = (
  inputFunc = '',
  i = 0,
  block = { v: '', u: [], t: BlockType.na },
) => {
  let count = i;
  const evalStr = inputFunc.charAt(i);

  if (count === inputFunc.length) {
    return { i: count, block };
  }

  if (numberCheck(evalStr)) {
    return addBlockNum(inputFunc, count, {
      v: evalStr,
      u: [],
      t: BlockType.val,
    });
  }
  if (evalStr === '^') {
    return addBlockFactorial(inputFunc, count + 1, {
      v: evalStr,
      u: [],
      t: BlockType.ao,
    });
  }
  if (['+', '-', '*', '/'].includes(evalStr)) {
    count += 1;

    return { i: count, block: { v: evalStr, u: [evalStr], t: BlockType.ao } };
  }
  if (['(', ')'].includes(evalStr)) {
    count += 1;

    return { i: count, block: { v: evalStr, u: [evalStr], t: BlockType.ps } };
  }

  return addBlockFunc(inputFunc, count, {
    v: evalStr,
    u: [],
    t: BlockType.func,
  });
};

const getEachBlock: BlockCycle = (
  inputFunc = '',
  blocks = [],
  count = 0,
  previousValueUnit = undefined,
  ansInclude = false,
) => {
  const counti = count;
  if (counti === inputFunc.length) {
    return { blocks, inputFunc, i: counti, ansInclude };
  }
  const { i, block } = runEachStep(inputFunc, counti);
  let checkAnsInclude = ansInclude;

  if (block.t === BlockType.unit) {
    const unitSplits = block.u[0].split(/(\/|\*)/);
    let funcCheckResult: [boolean, number] = [false, 0];
    unitSplits.find((unitSplit, index) => {
      const funcCheck = functions(unitSplit, previousValueUnit);
      if (funcCheck) funcCheckResult = [true, index];

      return funcCheck;
    });

    if (funcCheckResult[0]) {
      const blockULength = block.u[0].length;
      const unitRange = unitSplits.splice(0, funcCheckResult[1] - 1).join('');
      if (unitRange === '') {
        block.u = [];
        block.t = BlockType.val;
      } else {
        block.u = [unitRange];
      }
      blocks.push(block);

      return getEachBlock(
        inputFunc,
        blocks,
        i - blockULength + unitRange.length,
        previousValueUnit,
        checkAnsInclude,
      );
    }
    blocks.push(block);
  } else if (block.t === BlockType.func) {
    const funcCheck = functions(block.v, previousValueUnit);
    if (block.v.toUpperCase() === 'ANS') checkAnsInclude = true;
    blocks.push(funcCheck || block);
  } else {
    blocks.push(block);
  }

  return getEachBlock(inputFunc, blocks, i, previousValueUnit, checkAnsInclude);
};

// const blocks: Block[] = [{ v: 'pp', u: 'wwa', t: 'val' }];
// getEachBlock('10(cm)/20').blocks.forEach((t) => {
//   console.log(t.v, t.u);
// });
// console.log(getEachBlock('123cm/s4+Func', []));
// console.log(getEachBlock('(123cm/s4)*Func(2000)', []));
// console.log(getEachBlock('(123cm/s4)*200', []));
// console.log(getEachBlock('123', []));
// console.log(getEachBlock('123+1', []));

export default getEachBlock;
