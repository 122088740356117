import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    cardRoot: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    eachSpace: {
      borderLeft: 'solid 5px #ccc',
      marginLeft: 20,
      height: 7,
    },
    eachinput: {
      padding: 5,
      minHeight: 49,
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    eachInputValue: {
      display: 'flex',
      padding: '0 5px',
      alignItems: 'center',
    },
    flex1: {
      flex: 1,
    },
    text: {
      padding: '8px',
    },
  }),
);

export default useStyles;
