import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as types from './convertTypes';
import { Calc } from '../logic/Calc';
import { Blocks } from '../logic/modules/blocks/Type';
import { singleUnitConvert } from '../logic/modules/unitCalc/unitConvert';
import mathCalc from '../logic/modules/converts/math';

const initialState: types.convertTypes = []; // dummy

const convertSlice = createSlice({
  name: 'calc',
  initialState,
  reducers: {
    calc: (state, action: PayloadAction<string>) => {
      if (!action.payload) return initialState;

      let lineIndex = 0;
      let previousValueUnit: { value: number; unit: string } | undefined;
      action.payload.split('\n').forEach((inputValue, index) => {
        lineIndex = index;
        const findCalc = state.find(
          ([calcResult, _]) => calcResult.id === index,
        );
        if (index !== 0) {
          previousValueUnit = {
            value: state[index - 1][0].value || 0,
            unit: state[index - 1][0].unit || '',
          };
        }

        if (findCalc) {
          if (findCalc[0].input !== inputValue || findCalc[0].ansInclude) {
            const calcResult = Calc(
              inputValue,
              findCalc[0].id,
              undefined,
              previousValueUnit,
            );
            let replaceBlocks: Blocks = [];
            const indexs: number[] = [];
            const oldBlocks = findCalc[0].blocks;
            const oldLength = oldBlocks.length;
            calcResult.blocks.some((newBlock, newIndex) => {
              if (oldLength === 0) {
                replaceBlocks = calcResult.blocks;
                indexs.length = 0;

                return true;
              }
              if (newIndex > oldLength - 1) {
                replaceBlocks[newIndex] = newBlock;
                indexs.push(newIndex);

                return false;
              }

              const oldBlock = oldBlocks[newIndex];
              if (newBlock.t !== oldBlock.t) {
                replaceBlocks = calcResult.blocks;

                return true;
              }
              if (
                newBlock.v !== oldBlock.v ||
                newBlock.u[0] !== oldBlock.u[0]
              ) {
                replaceBlocks[newIndex] = newBlock;

                indexs.push(newIndex);

                return false;
              }

              replaceBlocks[newIndex] = newBlock;

              return false;
            });

            const newClones = state[findCalc[0].id][1].map((clone) => {
              let newClone: types.copy;
              if (indexs.length !== 0) {
                indexs.forEach((i) => {
                  clone.blocks.splice(i, 1, replaceBlocks[i]);
                });

                newClone = clone;
              } else {
                newClone = Object.assign(clone, { blocks: replaceBlocks });
              }

              if (newClone.blocks.length > calcResult.blocks.length) {
                newClone.blocks.splice(
                  newClone.blocks.length - 1,
                  newClone.blocks.length - calcResult.blocks.length,
                );
              }

              if (findCalc[0].unit !== calcResult.unit) {
                newClone.convertUnit = undefined;
                newClone.convertValue = undefined;
                newClone.prefix = undefined;
                newClone.prefixValue = undefined;
              }

              const newCloneResult = Calc(
                '',
                0,
                newClone.blocks,
                previousValueUnit,
              );
              newClone.value = newCloneResult.value || 0;
              newClone.unit = newCloneResult.unit || '';
              if (newClone.prefix)
                newClone.prefixValue = newClone.value / newClone.prefix;

              if (newClone.unit && newClone.convertUnit) {
                newClone.convertValue = singleUnitConvert(
                  newClone.value,
                  newClone.convertUnit,
                  calcResult.kind || '',
                );
              }

              return newClone;
            });

            state.splice(findCalc[0].id, 1, [calcResult, newClones]);
          }
        } else {
          const calcResult = Calc(
            inputValue,
            index,
            undefined,
            previousValueUnit,
          );
          state.push([
            calcResult,
            [
              {
                value: calcResult.value || 0,
                unit: calcResult.unit || '',
                blocks: calcResult.blocks,
                isOpen: false,
              },
            ],
          ]);
        }
      });

      state.splice(lineIndex + 1, state.length - lineIndex + 1);

      return state;
    },
    reset: () => {
      return [];
    },
    cloneCalc: (state, action: PayloadAction<types.copyCalc>) => {
      const { resultIndex, copyIndex, blockIndex, inputValue } = action.payload;
      const clone = state[resultIndex][1][copyIndex];
      const { value, unit } = resultIndex
        ? state[resultIndex - 1][0]
        : { value: undefined, unit: undefined };
      clone.blocks[blockIndex].v = inputValue?.toString() || '';
      const calcResult = Calc('', 0, clone.blocks, { value, unit });
      clone.value = calcResult.value || 0;
      clone.unit = calcResult.unit || '';

      if (clone.prefix) clone.prefixValue = clone.value / clone.prefix;

      if (clone.convertUnit) {
        clone.convertValue = singleUnitConvert(
          clone.value,
          clone.convertUnit,
          calcResult.kind || '',
        );
      }

      return state;
    },
    cloneAdd: (state, action: PayloadAction<number>) => {
      const result = state[action.payload];
      result[1].push({
        value: result[0].value || 0,
        unit: result[0].unit || '',
        blocks: result[0].blocks,
        isOpen: false,
      });

      return state;
    },
    cloneDelete: (state, action: PayloadAction<types.copyDelete>) => {
      const { resultIndex, copyIndex } = action.payload;
      state[resultIndex][1].splice(copyIndex, 1);

      return state;
    },
    convertOpenAction: (state, action: PayloadAction<types.convertOpen>) => {
      const { resultIndex, copyIndex, isOpen } = action.payload;
      const newState = state;
      newState[resultIndex][1][copyIndex].isOpen = isOpen;

      return newState;
    },
    prefixCalcAction: (state, action: PayloadAction<types.prefixCalc>) => {
      const { resultIndex, copyIndex, prefix } = action.payload;
      const newState = state;
      newState[resultIndex][1][copyIndex].prefix = prefix;
      const prefixValue = mathCalc([
        newState[resultIndex][1][copyIndex].value,
        '/',
        prefix,
      ]);
      newState[resultIndex][1][copyIndex].prefixValue = Number(prefixValue);

      return newState;
    },
    variableCalcAction: (state, action: PayloadAction<types.variableCalc>) => {
      const { resultIndex, copyIndex, convertUnit } = action.payload;
      const newState = state;
      const replaceState = newState[resultIndex][1][copyIndex];
      replaceState.isOpen = false;
      replaceState.convertUnit = convertUnit;
      replaceState.convertValue = singleUnitConvert(
        replaceState.value,
        convertUnit,
        newState[resultIndex][0].kind || '',
      );

      return newState;
    },
  },
});

export default convertSlice;
