import React, { FC, useRef, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { modalState } from 'Hooks/modalRecoils';
import Grid from '@material-ui/core/Grid';
import Modal from 'containers/templates/Modal';
import ConvertResults from '../organisms/ConvertResults';
import useConvert from '../../Hooks/useConvert';
import useStyles from './UseStyle';
import EachInputForm from '../organisms/EachInputForm';
import KeyForm from '../organisms/KeyForm';
import TextInputArea from '../organisms/TextInputArea';

const Calculator: FC = () => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [inputState, inputChange] = useState({ input: '', selection: 0 });
  const history = useHistory();
  const location = useLocation();
  const [_, setModal] = useRecoilState(modalState);

  const {
    convertState,
    convertCalc,
    convertReset,
    copyCalc,
    copyAdd,
    copyDelete,
    convertOpen,
    prefixCalc,
    variableCalc,
  } = useConvert();

  const handleSubmit = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    if (inputRef.current?.value) {
      inputChange({
        input: inputRef.current.value,
        selection: e.target.selectionStart,
      });
      convertCalc(inputRef.current.value);
    } else {
      convertReset();
      inputChange({ input: '', selection: 0 });
    }
    history.push(`/?formula=${encodeURI(inputRef.current?.value || '')}`);
  };

  const handleClick = (
    e: React.MouseEvent<HTMLTextAreaElement, MouseEvent>,
  ) => {
    e.preventDefault();
    inputChange({
      ...inputState,
      selection: inputRef.current?.selectionStart || 0,
    });
  };

  const keyInitialState: number[] = [];
  const [keyState, keyChange] = useState(keyInitialState);

  const inputSupport = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined,
    addStr: string,
    count = 0,
  ) => {
    if (e) e.preventDefault();
    const currentText = inputState.input;
    const { selection } = inputState;
    const input =
      currentText.substring(0, inputState.selection - (addStr === '' ? 1 : 0)) +
      addStr +
      currentText.substring(inputState.selection, currentText.length);
    inputChange({
      input,
      selection: selection + addStr.length - count - (addStr === '' ? 1 : 0),
    });
    convertCalc(input);
    history.push(`/?formula=${encodeURI(input)}`);
    inputRef.current?.focus();
    setTimeout(
      () =>
        inputRef.current?.setSelectionRange(
          selection + addStr.length - count,
          selection + addStr.length - count,
        ),
      10,
    );
  };

  useEffect(() => {
    const uriCheck = decodeURI(location.search)
      .split('?')
      .map((arr) => arr.split('='))
      .find((arr) => arr[0] === 'formula');
    const input = uriCheck ? uriCheck[1] : '';
    inputChange({ input, selection: 0 });
    convertCalc(input);
    if (!input) {
      const access = document.cookie
        ?.split('; ')
        ?.find((row) => row.startsWith('access'))
        ?.split('=')[1];

      if (!access) {
        setModal((c) => ({ ...c, modal: true, explain: true }));
        document.cookie = 'access=true';
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles();

  return (
    <section>
      <Grid
        container
        spacing={0}
        className={classes.topcontainer}
        justify="center"
      >
        <Grid item md={6} className={classes.flex}>
          <TextInputArea
            {...{
              inputRef,
              handleSubmit,
              handleClick,
              inputState,
              inputChange,
              convertReset,
              keyState,
              keyChange,
              inputSupport,
              history,
            }}
          />
        </Grid>
        <Grid item md={6} className={classes.flex}>
          <ConvertResults {...{ convertState }} />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={0}
        className={classes.topcontainer}
        style={{ marginTop: 0 }}
        justify="center"
      >
        <KeyForm
          {...{
            inputState,
            inputChange,
            convertCalc,
            inputRef,
            convertReset,
            inputSupport,
            keyState,
            keyChange,
          }}
        />
      </Grid>
      <Grid
        container
        spacing={0}
        className={classes.topcontainer}
        justify="center"
        style={{ marginTop: 0 }}
      >
        <Grid item xs={12} className={classes.flex} style={{ padding: 10 }}>
          {convertState.map(([result, copies]) =>
            copies.map((copy, copyIndex) => (
              <>
                {copyIndex === 0 ? <div style={{ marginTop: 20 }} /> : ''}
                <EachInputForm
                  {...{
                    copy,
                    copyCalc,
                    copyAdd,
                    copyDelete,
                    copyIndex,
                    convertOpen,
                    prefixCalc,
                    variableCalc,
                  }}
                  kind={result.kind}
                  resultIndex={result.id}
                  key={`${result.id.toString()}-${copyIndex.toString()}`}
                />
              </>
            )),
          )}
        </Grid>
      </Grid>
      <Modal
        {...{ inputSupport, copyCalc, copyAdd, convertReset, inputChange }}
      />
    </section>
  );
};

export default Calculator;
