import React, { FC } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useClass = makeStyles(() =>
  createStyles({
    paper: {
      padding: 20,
      marginTop: 20,
      width: '100%',
    },
  }),
);

const TermService: FC = () => {
  const classes = useClass();

  return (
    <Grid
      container
      style={{ maxWidth: 600, margin: 'auto', padding: 50, lineHeight: 2 }}
    >
      <Paper className={classes.paper}>
        <h1>Term of service</h1>
        <pre style={{ fontSize: 14, whiteSpace: 'pre-wrap' }}>
          {`
Please read these terms of service carefully before using website operated by us.
Please use calculation result by your own responsible. We have not responsible any damage case by this web application.


Conditions of Use We will provide their services to you, which are subject to the conditions stated below in this document. Every time you visit this website, use its services, you accept the following conditions.
This is why we urge you to read them carefully.
Communications The entire communication with us is electronic.
Every time you send us an email or visit our website, you are going to be communicating with us.
You hereby consent to receive communications from us.
Applicable Law By visiting this website, you agree that the laws of the Japan, without regard to principles of conflict laws, will govern these terms of service, or any dispute of any sort that might come between [name] and you, or its business partners and associates. Disputes Any dispute related in any way to your visit to this website from us shall be arbitrated by state or federal court Japan and you consent to exclusive jurisdiction and venue of such courts.
Comments, Reviews, and Emails Visitors may post content as long as it is not obscene, illegal, defamatory, threatening, infringing of intellectual property rights, invasive of privacy or injurious in any other way to third parties.
Content has to be free of software viruses, political campaign, and commercial solicitation. We reserve all rights (but not the obligation) to remove and/or edit such content.
License and Site Access We grant you a limited license to access and make personal use of this website. You are not allowed to download or modify it.
This may be done only with written consent from us.
We reserve all rights to terminate accounts, edit or remove content sole discretion.
          `}
        </pre>
        Azy
      </Paper>
    </Grid>
  );
};

export default TermService;
