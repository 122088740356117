import { Variable } from '../units/UnitExport';

const convertTemp = (unit: string): [string, string[]] => {
  const findTemp = Object.entries(Variable.Tempreture).find(
    ([tempUnits, _]) => {
      return tempUnits.split(', ').find((tempUnit) => {
        if (unit === tempUnit) return true;

        return false;
      });
    },
  );

  if (findTemp) return [findTemp[1], ['K']];

  return ['', []];
};

export default convertTemp;
