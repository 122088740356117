import { MetricPrefix, SiAlts, SiDer, SiBase } from '../units/UnitExport';
import { meticValues, escapeRegExp } from '../aoRegExps';
import convertType from './Type';
import { convertSIs } from './convertSI';

const convertSiAlter = (unit: string): convertType => {
  let evalSi: RegExpExecArray | null = null;
  const findVal = Object.entries(SiAlts).find((siAlt) => {
    return siAlt[1].find((siA) => {
      evalSi = new RegExp(`${String(meticValues)}?(${escapeRegExp(siA)})`).exec(
        unit,
      );

      return evalSi;
    });
  });

  if (findVal) {
    return convertSIs(
      evalSi ? String(evalSi[1] ? MetricPrefix[evalSi[1]] : '1') : '1',
      SiDer[findVal[0]] || SiBase[findVal[0]],
    );
  }

  return null;
};

export default convertSiAlter;

// console.log(convertSiAlter('MPa'));
// console.log(convertSiAlter('cm'));
