import React, { FC } from 'react';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import FunctionsIcon from '@material-ui/icons/Functions';
import InputButton, { PropsFc } from '../atom/InputButton';
import InputTitle from '../atom/InputTitle';

const FunctionInput: FC<PropsFc> = (props) => {
  const { inputSupport } = props;

  return (
    <Grow in>
      <Paper style={{ margin: '10px 10px 0', padding: '5px 10px' }}>
        <InputTitle title="functions">
          <FunctionsIcon fontSize="small" />
        </InputTitle>
        <InputButton num="sin()" count={1} {...{ inputSupport }} />
        <InputButton num="cos()" count={1} {...{ inputSupport }} />
        <InputButton num="tan()" count={1} {...{ inputSupport }} />
        <InputButton num="log()" {...{ inputSupport }} />
        <InputButton num="log10()" {...{ inputSupport }} />
        <InputButton num="π(pi)" value="pi" {...{ inputSupport }} />
        <InputButton num="" value="^2" {...{ inputSupport }}>
          x<sup>2</sup>
        </InputButton>
        <InputButton num="Ans" {...{ inputSupport }} />
      </Paper>
    </Grow>
  );
};

export default FunctionInput;
