import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Tooltip from '@material-ui/core/Tooltip';
import CopyToClipBoard from 'react-copy-to-clipboard';

type Props = {
  tooltip?: string;
  text: string;
  style?: { [key: string]: string | number };
};

const ClipBordCopy: React.FC<Props> = (props) => {
  const { children, text, style, tooltip } = props;

  return (
    <Tooltip arrow placement="top" title={tooltip ?? `COPIE`}>
      <CopyToClipBoard text={text}>
        <IconButton {...{ style }}>{children ?? <AssignmentIcon />}</IconButton>
      </CopyToClipBoard>
    </Tooltip>
  );
};

export default ClipBordCopy;
