import React, { FC } from 'react';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import AppsIcon from '@material-ui/icons/Apps';
import InputButton, { PropsFc } from '../atom/InputButton';
import InputTitle from '../atom/InputTitle';

const NumberInput: FC<PropsFc> = (props) => {
  const { inputSupport } = props;

  return (
    <Grow in>
      <Paper style={{ margin: '10px 10px 0', padding: '5px 10px' }}>
        <InputTitle title="number">
          <AppsIcon fontSize="small" />
        </InputTitle>
        <InputButton num={0} {...{ inputSupport }} />
        <InputButton num={1} {...{ inputSupport }} />
        <InputButton num={2} {...{ inputSupport }} />
        <InputButton num={3} {...{ inputSupport }} />
        <InputButton num={4} {...{ inputSupport }} />
        <InputButton num={5} {...{ inputSupport }} />
        <InputButton num={6} {...{ inputSupport }} />
        <InputButton num={7} {...{ inputSupport }} />
        <InputButton num={8} {...{ inputSupport }} />
        <InputButton num={9} {...{ inputSupport }} />
        <InputButton num="." {...{ inputSupport }} />
      </Paper>
    </Grow>
  );
};

export default NumberInput;
