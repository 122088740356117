import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex: {
      flexGrow: 1,
      width: '50%',
    },
    topcontainer: {
      maxWidth: 1280,
      margin: 'auto',
      marginTop: theme.spacing(3),
      fontSize: 16,
    },
    inputarea: {
      width: '100%',
      border: 'none',
      outline: 'none',
      padding: 20,
      resize: 'none',
      lineHeight: '50px',
      fontSize: 16,
      letterSpacing: 1.2,
    },
    resultarea: {
      width: '100%',
      height: '100%',
      backgroundColor: '#e1f8ff',
      padding: 20,
      whiteSpace: 'break-spaces',
    },
    gridarea: {
      width: '100%',
      height: '100%',
      padding: 20,
    },
    result: {
      marginTop: 10,
      padding: '5px 15px',
      letterSpacing: 1.1,
    },
    cardRoot: {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
      alignItems: 'center',
      minHeight: 55,
    },
    eachinput: {
      padding: 10,
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    eachInputValue: {
      padding: '0 5px',
    },
    eachInputResult: {
      padding: '0 20px',
    },
    convertformula: {
      display: 'block',
      fontSize: 10,
      color: '#606c76',
    },
  }),
);

export default useStyles;
