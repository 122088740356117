import React, { FC } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

export type KeyProps = {
  keyChange: React.Dispatch<React.SetStateAction<number[]>>;
  keyState: number[];
  convertReset?: () => void;
  inputSupport?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    addStr: string,
    count?: number,
  ) => void;
};

type Props = { title: string; count: number };
const KeyFormButton: FC<Props & KeyProps> = (props) => {
  const { children, title, count, keyState, keyChange, inputSupport } = props;
  const included = keyState.includes(count);

  return (
    <Tooltip arrow title={title}>
      <IconButton
        color={included ? 'primary' : 'default'}
        style={{ marginRight: 20 }}
        onClick={(e) => {
          if (inputSupport) {
            inputSupport(e, '');
          } else {
            keyChange((prev) =>
              included ? prev.filter((n) => n !== count) : prev.concat([count]),
            );
          }
        }}
      >
        {children}
      </IconButton>
    </Tooltip>
  );
};

export default KeyFormButton;
