import React, { useState, FC } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';

// Material-UIアイコン取得
import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Link } from 'react-router-dom';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import Logo from '../../images/logo_p.png';
import OpenYoutube from './OpenYoutube';

// スタイルを適用する
const useStyles = makeStyles(() =>
  createStyles({
    appBar: {
      height: 50,
    },
    toolBar: {
      minHeight: 50,
      textAlign: 'center',
    },
    headerLogo: {
      color: 'inherit',
      marginLeft: 20,
      textAlign: 'center',
    },
    headerTitleStyle: {
      color: 'inherit',
      textAlign: 'center',
    },
    avatar: {
      margin: '8px',
    },
    drawerList: {
      width: 200,
      height: '100%',
    },
    link: {
      color: 'inherit',
      textDecoration: 'none',
    },
  }),
);

const NavBar: FC = () => {
  // Drawerの状態
  const [isOpen, setOpen] = useState(false);

  // CSSを適用する。
  const classes = useStyles();

  // Drawerの状態を変更する関数。
  const toggleDrawerNav = () => {
    setOpen(!isOpen);
  };

  const closeDrawerNav = () => {
    setOpen(false);
  };

  return (
    <>
      {/* 上部のバー */}
      <AppBar
        position="static"
        aria-label="Global Navi"
        className={classes.appBar}
      >
        <Toolbar className={classes.toolBar}>
          <IconButton onClick={toggleDrawerNav} aria-label="SideMenu">
            <MenuIcon style={{ color: '#fff' }} />
          </IconButton>
          <Link
            to="/"
            style={{
              width: '100%',
              margin: 'auto',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            className={classes.link}
          >
            <img
              src={Logo}
              alt="Engineer Calculator. logo"
              style={{ marginRight: 20 }}
            />
            <Typography className={classes.headerTitleStyle} variant="h3">
              SMART CALCULATOR
            </Typography>
          </Link>
          <OpenYoutube />
        </Toolbar>
      </AppBar>
      {/* サイドメニュー */}
      <Drawer open={isOpen} onClose={closeDrawerNav}>
        <div className={classes.drawerList}>
          <List>
            <ListItem button onClick={closeDrawerNav}>
              <Link to="/" className={classes.link} style={{ display: 'flex' }}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </Link>
            </ListItem>
            <ListItem button onClick={closeDrawerNav}>
              <Link
                to="/Subscription"
                className={classes.link}
                style={{ display: 'flex' }}
              >
                <ListItemIcon>
                  <SubscriptionsIcon />
                </ListItemIcon>
                <ListItemText primary="Subscription" />
              </Link>
            </ListItem>
            <ListItem button onClick={closeDrawerNav}>
              <Link
                to="/termofservice"
                className={classes.link}
                style={{ display: 'flex' }}
              >
                <ListItemIcon>
                  <CheckBoxIcon />
                </ListItemIcon>
                <ListItemText primary="Term of Service" />
              </Link>
            </ListItem>
          </List>
          <Divider />
        </div>
      </Drawer>
    </>
  );
};

export default NavBar;
