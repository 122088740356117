import { create, all } from 'mathjs';

const config = { number: 'BigNumber', precision: 64 };
const math = create(all, config);

const mathCalc = (args: (number | string)[]): number => {
  if (math.evaluate) {
    try {
      return Number(math.evaluate(args.map((a) => a.toString()).join('')));
    } catch (e) {
      return 0;
    }
  }

  return 0;
};

export default mathCalc;
