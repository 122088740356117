import { AoArray, getRegExp } from '../aoRegExps';
import convertSI from './convertSI';
import convertSiAlter from './convertSiAlter';
import convertVariable from './convertVariable';
import convertType from './Type';

const Convert = (units: string): convertType => {
  const unitArray: Array<convertType> = [];
  units.split(getRegExp(true, ...AoArray, '(', ')')).forEach((unit) => {
    if (!unit) {
      return;
    }
    if (AoArray.concat(['(', ')']).includes(unit)) {
      unitArray.push([unit, [unit]]);
    } else {
      const evalUnit =
        convertVariable(unit) || convertSiAlter(unit) || convertSI(unit);
      if (evalUnit) {
        evalUnit[1].unshift('(');
        evalUnit[1].push(')');
        unitArray.push([`${evalUnit[0]}`, evalUnit[1]]);
      } else {
        unitArray.push(['1', [unit]]);
      }
    }
  });

  return unitArray.reduce((sum, valueUnits) => {
    if (sum && valueUnits) {
      return [sum[0] + valueUnits[0], sum[1].concat(valueUnits[1])];
    }

    return sum;
  });
};

export default Convert;

// console.log(Convert('(mm2)'));
// console.log(Convert('feet/s4'));
// console.log(Convert('mm2/s4'));
// console.log(Convert('ft2'));
// console.log(Convert('"/s4'));
// console.log(Convert('MPa/(cm*cs2)'));
// console.log(Convert('cs2'));
// console.log(Convert('mm2'));
