import React, { FC } from 'react';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import AddIcon from '@material-ui/icons/Add';
import InputButton, { PropsFc } from '../atom/InputButton';
import InputTitle from '../atom/InputTitle';

const OperatorInput: FC<PropsFc> = (props) => {
  const { inputSupport } = props;

  return (
    <Grow in>
      <Paper style={{ margin: '10px 10px 0', padding: '5px 10px' }}>
        <InputTitle title="operator">
          <AddIcon fontSize="small" />
        </InputTitle>
        <InputButton num="+" {...{ inputSupport }} />
        <InputButton num="-" {...{ inputSupport }} />
        <InputButton num="x (*)" value="*" {...{ inputSupport }} />
        <InputButton num="/" {...{ inputSupport }} />
        <InputButton num="(" {...{ inputSupport }} />
        <InputButton num=")" {...{ inputSupport }} />
      </Paper>
    </Grow>
  );
};

export default OperatorInput;
