import { Blocks } from '../blocks/Type';
import unitMultiply from './unitMultiply';
import unitSortStr from './unitSortStr';
import unitSort from './unitSort';

const unitCalc = (blocks: Blocks): string => {
  const splitBlocks: Blocks[] = [[]];

  blocks.forEach((block) => {
    if (['+', '-'].includes(block.v)) {
      splitBlocks.push([]);
    } else {
      splitBlocks[splitBlocks.length - 1].push(block);
    }
  });

  const calcBlocks = splitBlocks.map((sblocks) => {
    if (!sblocks) {
      return '';
    }

    return unitSortStr(unitSort(unitMultiply(sblocks)));
  });

  if (calcBlocks.length > 1) {
    const newCalcBlocks = [...new Set(calcBlocks)];
    if (newCalcBlocks.length === 1) {
      return newCalcBlocks[0];
    }

    return newCalcBlocks.reduce((sumStr: string, cUnit): string => {
      return `${sumStr}${cUnit}`;
    }, 'Error: "+", "-" ');
  }

  return calcBlocks[0];
};

export default unitCalc;

// const blocks: Blocks = [
//   { v: '200*((10e-3))', u: ['(', 'm', '/', 's', ')'], t: 'unit' },
//   { v: '/', u: ['/'], t: 'ao' },
//   {
//     v: '20*((10e-4))',
//     u: ['(', 'm', '/', 's', '*', 'p', ')'],
//     t: 'unit',
//   },
//   { v: '/', u: ['/'], t: 'ao' },
//   {
//     v: '20*((10e-4))',
//     u: ['(', 'm', '/', 's', ')'],
//     t: 'unit',
//   },
// ];

// const blocks1: Blocks = [
//   { v: '200*((10e-3))', u: [], t: 'val' },
//   { v: '/', u: ['/'], t: 'ao' },
//   { v: '10', u: [], t: 'val' },
// ];

// const blocks2: Blocks = [
//   { v: '200*((10e-3))', u: [], t: 'val' },
//   { v: '/', u: ['/'], t: 'ao' },
//   { v: '10', u: ['m'], t: 'val' },
// ];

// console.log(unitCalc(blocks));
// console.log(unitCalc(blocks1));
// console.log(unitCalc(blocks2));
