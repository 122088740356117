import React, { FC } from 'react';
import CardMedia from '@material-ui/core/CardMedia';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import colors from '../templates/colors';
import * as types from '../../Hooks/convertTypes';

type Props = {
  resultIndex: number;
  copyIndex: number;
  copyAdd: types.copyAddFc;
  copyDelete: types.copyDeleteFc;
};

const EachInputAdd: FC<Props> = (props) => {
  const { resultIndex, copyIndex, copyAdd, copyDelete } = props;
  const color = colors(resultIndex);

  return (
    <CardMedia
      onClick={
        copyIndex === 0
          ? () => copyAdd(resultIndex)
          : () => copyDelete({ copyIndex, resultIndex })
      }
      style={{
        padding: 1,
        alignItems: 'center',
      }}
    >
      <IconButton>
        <Icon style={{ color }}>
          {copyIndex === 0 ? 'add_circle' : 'highlight_off'}
        </Icon>
      </IconButton>
    </CardMedia>
  );
};

export default EachInputAdd;
