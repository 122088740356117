/* eslint-disable import/prefer-default-export */
import { atom } from 'recoil';

export const initialModal = {
  modal: false,
  explain: false,
  explainWord: 'Please input formula!!.', // 'You can input the formula into input area.',
  textInput: false,
  answer: false,
  clone: false,
  enjoy: false,
};

export const modalState = atom({
  key: 'modal',
  default: initialModal,
});
